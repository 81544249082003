<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br />
        <br />
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit RTB Zone</strong>
            </div>
            <div class="text-muted">Edit RTB Zone information</div>
          </div>
          <div class="card-body">
            <a-form v-if="rtbzoneData" :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>RTB Zone's name.</span>
                      </template>
                      <a-input type="text" placeholder="RTB Zone Name" v-decorator="['name', {
          initialValue: rtbzoneData.Name,
          rules: [
            { required: true, message: 'Name is required.' },
            { max: 64, message: 'Maximum 64 characters allowed.' }
          ]
        }]" />
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Platform Domain">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>The canonical domain name of the advertising system that is making bid requests to this
                          RTB
                          zone. It is used for checking if the system is publisher’s authorized seller of inventory, by
                          checking publisher’s ads.txt or app-ads.txt file.</span>
                      </template>
                      <a-input type="text" v-decorator="['platformDomain', {
          rules: [{ max: 32, message: 'Maximum 32 characters allowed.' },
          { pattern: domainRegExp, message: '\'www.\', \'http://\' and \'https://\' are not allowed. Please input pure top-level domain, for example: google.com' }], initialValue: rtbzoneData.PlatformDomain
        }]" placeholder="Advertising Platform Domain" />
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="sellers.json Domain">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>The canonical domain where advertising system hosts its sellers.json file. Usually it is
                          the
                          same domain as Platform Domain.</span>
                      </template>
                      <a-input type="text" v-decorator="['sellersJsonDomain', {
          rules: [{ max: 32, message: 'Maximum 32 characters allowed.' },
          { pattern: domainRegExp, message: '\'www.\', \'http://\' and \'https://\' are not allowed. Please input pure top-level domain, for example: google.com' }], initialValue: rtbzoneData.SellersJsonDomain
        }]" placeholder="sellers.json Domain" />
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Margin">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Margin percentage to add to bid floor which is sent to advertiser's Remote RTB Zone. For
                          example, if publisher's bid floor is $5 and Margin is 20%, advertiser will receive $6 bid
                          floor.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%" v-decorator="['margin', {
          initialValue: rtbzoneData.Margin,
        }]" placeholder="Margin">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publisher Revenue Share">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Percentage of a revenue (bid price minus bid floor) from Remote RTB Zone's advertiser that
                          RTB
                          zone's publisher earns.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%" v-decorator="['publisherRevShare', {
          initialValue: rtbzoneData.PublisherRevShare,
        }]" placeholder="Publisher Revenue Share">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impression Tracking Method">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>How impressions are tracked.</span>
                      </template>
                      <a-select
                        v-decorator="['trackingMethod', { initialValue: rtbzoneData.ImpressionTrackingMethod },]"
                        placeholder="Impression Tracking Method" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option :value="0">
                          nurl
                        </a-select-option>
                        <a-select-option :value="1">
                          burl
                        </a-select-option>
                        <a-select-option :value="2">
                          Markup Display
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. QPS">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum queries per second - Maximum number of requests to receive from the RTB Zone in
                          one
                          second. Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Max. QPS"
                               v-decorator="['maxQps', { initialValue: rtbzoneData.MaxQps }]" />
                    </a-tooltip>
                  </a-form-item>

                </div>
                <div class="w-100">

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Check ads.txt">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Check ads.txt for the site.</span>
                      </template>
                      <a-select v-decorator="['checkAdsTxt', { initialValue: rtbzoneData.CheckAdsTxt },]"
                        placeholder="Check ads.txt" :showSearch="true" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value="0">
                          No
                        </a-select-option>
                        <a-select-option :value="1">
                          Yes, allow supplier until crawled
                        </a-select-option>
                        <a-select-option :value="2">
                          Yes, deny supplier until crawled
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Check app-ads.txt">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Check app-ads.txt for the app.</span>
                      </template>
                      <a-select v-decorator="['checkAppAdsTxt', { initialValue: rtbzoneData.CheckAppAdsTxt },]"
                        placeholder="Check app-ads.txt" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option :value="0">
                          No
                        </a-select-option>
                        <a-select-option :value="1">
                          Yes, allow supplier until crawled
                        </a-select-option>
                        <a-select-option :value="2">
                          Yes, deny supplier until crawled
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Check sellers.json">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Check sellers.json for the app.</span>
                      </template>
                      <a-select v-decorator="['checkSellersJson', { initialValue: rtbzoneData.CheckSellersJson },]"
                        placeholder="Check sellers.json" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option :value="0">
                          No
                        </a-select-option>
                        <a-select-option :value="1">
                          Yes, allow supplier until crawled
                        </a-select-option>
                        <a-select-option :value="2">
                          Yes, deny supplier until crawled
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domains/Brands Blacklist">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of domains or brand names to block.</span>
                      </template>
                      <div class="list-flex">
                        <a-select class="select-width"
                          v-decorator="['AdDomainsBrandsBlacklist', { initialValue: rtbzoneData.AdDomainsBrandsBlacklist === '' ? [] : rtbzoneData.AdDomainsBrandsBlacklist.split('\n') }]"
                          @change="handleAdDomains" placeholder="Ad Domain/Brands Blacklist" :showSearch="true"
                          mode="tags" :tokenSeparators="[' ']" :filterOption="true" optionFilterProp="children">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('brands')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Apps Blacklist">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Blacklist of applications by their application identifiers. On Android, these should be
                          bundle or package names (e.g., com.foo.mygame), on iOS, these are numeric IDs.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-decorator="['AdAppsBlacklist', { initialValue: rtbzoneData.AdAppsBlacklist === '' ? [] : rtbzoneData.AdAppsBlacklist.split('\n') }]"
                          @change="handleAppsBlacklist" placeholder="Ad Apps Blacklist" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('apps')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Auto Optimize">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Automatic requests optimization.</span>
                      </template>
                      <a-switch v-if="autoOpt !== undefined" checked-children="Enabled" un-checked-children="Disabled"
                        v-model="autoOpt" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Pop adm XML">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable XML in adm field in bid response for Pop ads.</span>
                      </template>
                      <a-switch v-if="popAdmXml !== undefined" checked-children="Enabled" un-checked-children="Disabled"
                        v-model="popAdmXml" />
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Requests Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of requests to make per day. The system will even out distribution of bids
                          throughout
                          the day, ie. "day shaping". Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Requests Daily Limit"
                        v-decorator="['requestsDailyLimit', { initialValue: rtbzoneData.RequestsDailyLimit, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout the
                          day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: rtbzoneData.BidsDailyLimit, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Limit Per IP">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Daily limit of impressions per IP address. Set to 0 for unlimited impressions per IP address.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsLimitPerIp', { initialValue: rtbzoneData.ImpressionsLimitPerIp, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of bids throughout
                          the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsDailyLimit', { initialValue: rtbzoneData.ImpressionsDailyLimit, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Spent Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to earn from advertiser. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" step="0.0001" placeholder="Spent Daily Limit"
                        v-decorator="['spentDailyLimit', { initialValue: rtbzoneData.SpentDailyLimit, rules: [{ validator: cpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Supply Filters</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Banner Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Banner Ads.</span>
                      </template>
                      <a-switch v-if="bannerEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="bannerEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Video Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Video Ads.</span>
                      </template>
                      <a-switch v-if="videoEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="videoEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Audio Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Audio Ads.</span>
                      </template>
                      <a-switch v-if="audioEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="audioEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Native Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Native Ads.</span>
                      </template>
                      <a-switch v-if="nativeEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="nativeEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Pop Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Pop Ads.</span>
                      </template>
                      <a-switch v-if="popEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="popEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Web Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Web traffic.</span>
                      </template>
                      <a-switch v-if="webEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="webEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable App traffic.</span>
                      </template>
                      <a-switch v-if="appEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="appEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Min. Bidfloor">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Allow bid request only if Bidfloor is greater than this value.</span>
                      </template>
                      <a-input type="float" min="0" max="100" step="0.0001" placeholder="Min. Bidfloor"
                        v-decorator="['minBidfloor', { initialValue: rtbzoneData.MinBidfloor, rules: [{ validator: minBidValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. Bidfloor">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Allow bid request only if Bidfloor is less than this value.</span>
                      </template>
                      <a-input type="float" min="0" max="100" step="0.0001" placeholder="Max. Bidfloor"
                        v-decorator="['maxBidfloor', { initialValue: rtbzoneData.MaxBidfloor, rules: [{ validator: maxBidValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol"
                    label="Domains/Brands List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow publisher domains or brand names.</span>
                      </template>
                      <a-select
                        v-decorator="['domainsBrandsListMode', { initialValue: rtbzoneData.DomainsBrandsListMode }]"
                        placeholder="Domains/Brands List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Domains/Brands List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Domain Lists to block or allow.</span>
                      </template>
                      <a-select
                        v-decorator="['domainsBrandsList', { initialValue: (rtbzoneData.DomainsBrandsList === '' ? [] : rtbzoneData.DomainsBrandsList) }]"
                        placeholder="Please Select Domains/Brands Lists" :showSearch="true" :filterOption="true"
                        mode="multiple" optionFilterProp="children">
                        <a-select-option v-for="obj in activeDomainLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                      <!-- <div class="list-flex">
                        <a-select
                          v-decorator="['DomainsBrandsList', { initialValue: rtbzoneData.DomainsBrandsList === '' ? [] : rtbzoneData.DomainsBrandsList.split('\n') }]"
                          @change="handleDomainsBrands" placeholder="Domains/Brands List" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']" class="select-width">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('brands')"><i
                            class="fa fa-copy"></i>
                        </a-button>
                      </div> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Apps List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow publisher applications by their application identifiers.</span>
                      </template>
                      <a-select v-decorator="['appsListMode', { initialValue: rtbzoneData.AppsListMode }]"
                        placeholder="Apps List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Apps List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of App Lists to block or allow.</span>
                      </template>
                      <a-select
                        v-decorator="['appsList', { initialValue: (rtbzoneData.AppsList === '' ? [] : rtbzoneData.AppsList) }]"
                        placeholder="Please Select App Lists" :showSearch="true" :filterOption="true" mode="multiple"
                        optionFilterProp="children">
                        <a-select-option v-for="obj in activeAppLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                      <!-- <div class="list-flex">
                        <a-select
                          v-decorator="['AppsList', { initialValue: rtbzoneData.AppsList === '' ? [] : rtbzoneData.AppsList.split('\n') }]"
                          @change="handleAppsList" placeholder="Apps List" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('apps')"><i
                            class="fa fa-copy"></i>
                        </a-button>
                      </div> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Sizes List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow creatives' sizes.</span>
                      </template>
                      <a-select v-decorator="['sizesListMode', { initialValue: rtbzoneData.SizesListMode }]"
                        placeholder="Sizes List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Non Empty Referer">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Ad impression or click with no referer will be filtered.</span>
                      </template>
                      <a-switch v-if="nonEmptyRefererEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="nonEmptyRefererEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Referer Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Referer in ad impression or click and site URL should match.</span>
                      </template>
                      <a-switch v-if="refererMatchEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="refererMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="IP Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Real user's IP address in ad impression or click and user IP parameter should
                          match.</span>
                      </template>
                      <a-switch v-if="ipMatchEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="ipMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="UA Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Real user's User Agent in ad impression or click and user agent parameter should
                          match.</span>
                      </template>
                      <a-switch v-if="uaMatchEnabled !== undefined" checked-children="Enabled"
                        un-checked-children="Disabled" v-model="uaMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :style="{ marginTop: '3.76rem' }" :label-col="supplyLabelCol"
                    :wrapper-col="supplyWrapperCol" label="Sizes List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of creatives' sizes to block or allow.</span>
                      </template>
                      <a-select v-decorator="['sizesList', { initialValue: selectedSizes }]"
                        placeholder="Please select Sizes" :showSearch="true" :filterOption="true" mode="multiple"
                        optionFilterProp="children">
                        <a-select-option :value="`${size.size.width}x${size.size.height}`"
                          v-for="(size, index) in sizes" :key="index">{{ size.size.width }}x{{ size.size.height }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Pub ID List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow Pub IDs.</span>
                      </template>
                      <a-select v-decorator="['pubIdListMode', { initialValue: rtbzoneData.PubIdListMode }]"
                        placeholder="Pub ID List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Pub ID List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Pub IDs to block or allow.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-decorator="['PubIdList', { initialValue: rtbzoneData.PubIdList === '' ? [] : rtbzoneData.PubIdList.split('\n') }]"
                          @change="handlePubIdList" placeholder="Pub ID List" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('pubIds')"><i
                            class="fa fa-copy"></i>
                        </a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="CRID List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow Creative IDs.</span>
                      </template>
                      <a-select v-decorator="['CridListMode', { initialValue: rtbzoneData.CridListMode }]"
                        placeholder="CRID List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="CRID List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Creative IDs to block or allow.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-decorator="['CridList', { initialValue: rtbzoneData.CridList === '' ? [] : rtbzoneData.CridList.split('\n') }]"
                          @change="handleCridList" placeholder="CRID List" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('crids')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="IP Blacklists">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of IP Lists to use for IP block. Only "Not Global" IP Lists can be chosen.</span>
                      </template>
                      <a-select
                        v-decorator="['ipBlacklists', { initialValue: (rtbzoneData.IpBlacklists === '' ? [] : rtbzoneData.IpBlacklists) }]"
                        placeholder="Please select IP Lists" :showSearch="true" :filterOption="true" mode="multiple"
                        optionFilterProp="children">
                        <a-select-option v-for="obj in activeIpLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Supply Categories Filter</a-divider>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Enable IAB Categories">
                <a-tree checkable v-model="checkedComputedKeys" :auto-expand-parent="autoExpandParent"
                  :selected-keys="selectedKeys" :tree-data="treeData" @expand="onExpand" @select="onSelect"
                  optionFilterProp="children" />
              </a-form-item>
              <a-divider class="mt-3"></a-divider>
              <a-divider style="margin-top: 34px;">Demand</a-divider>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Campaigns">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Array of Campaigns' IDs to connect to the RTB Zone. This will make the Campaign Ad appear in RTB Zone response if all the targeting is matched.</span>
                    </template>
                    <a-select
                      v-decorator="['campaigns', { initialValue: (rtbzoneData.Campaigns === '' ? [] : rtbzoneData.Campaigns) }]"
                      placeholder="Please select Campaigns" :showSearch="true" :filterOption="true" mode="multiple"
                      optionFilterProp="children">
                      <a-select-option class="zones" v-for="obj in activeCampaigns" :key="obj.Id">[{{  obj.Id  }}] {{  obj.Name  }}
                      </a-select-option>
                    </a-select>
                  </a-tooltip>
                </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Remote RTB Zones">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Remote RTB Zones to connect to the RTB Zone. This will make the Remote RTB Zone
                      appear in RTB Zone response if all the targeting is matched.</span>
                  </template>
                  <a-select
                    v-decorator="['Remotertbzones', { initialValue: (rtbzoneData.Remotertbzones === '' ? [] : rtbzoneData.Remotertbzones) }]"
                    placeholder="Please select Remote RTB Zones" :showSearch="true" :filterOption="true" mode="multiple"
                    optionFilterProp="children">
                    <a-select-option class="zones" v-for="obj in activeRemoteRTBZones" :key="obj.Id">[{{ obj.Id }}] {{
          obj.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="XML DSP Pop Feeds">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Array of XML DSP Pop Feeds' IDs to connect to the RTB Zone. This will make the XML DSP Pop Feed appear in RTB Zone response if all the targeting is matched.</span>
                  </template>
                  <a-select
                    v-decorator="['dsppopfeeds', { initialValue: (rtbzoneData.Dsppopfeeds === '' ? [] : rtbzoneData.Dsppopfeeds) }]"
                    placeholder="Please select XML DSP Pop Feeds" :showSearch="true" :filterOption="true"
                    mode="multiple" optionFilterProp="children">
                    <a-select-option class="zones" v-for="obj in activeDsppopfeeds" :key="obj.Id">[{{ obj.Id }}] {{
          obj.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-divider style="margin-top: 34px;">Targeting</a-divider>
              <a-tabs default-active-key="1" class="mb-4">
                <a-tab-pane key="1" tab="Countries">
                  <a-row>
                    <a-input-search placeholder="Filter Countries" style="width: 200px" class="mb-2"
                      v-model="filterSearch" />
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half"
                        v-model="displayEnabledCountries" /> Display
                      Only Enabled</label>
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half" v-model="selectAllCountries"
                        @click="handleCountries" /> De/Select All</label>
                  </a-row>
                  <a-spin class="d-flex justify-content-center" v-if="enabledCountries.length === 0">
                    <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                  </a-spin>
                  <ul v-else class="list-group list-group-flush countryList" style="border: 1px solid #e4e9f0;">
                    <li class="list-group-item treeContainer" v-for="(item, index) in enabledCountries" :key="index"
                      :style="item.Enabled ? 'background-color: rgb(237 237 243 / 38%);' : ''">
                      <label class="col-sm cpos cpointer">
                        <input type="checkbox" class="global-checkbox" v-model="item.Enabled"
                          @click="enableCountry($event, item)" />
                        <span class="col-sm"><img :src="matchIcon(countries, item.CC)" /> {{ item.Country }} ({{
          item.CC
        }})</span>
                      </label>
                    </li>
                  </ul>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Device Types">
                  <ul class="list-group list-group-flush countryList" style="border: 1px solid #e4e9f0;">
                    <li v-for="item in deviceTypes" :key="item.index" class="list-group-item treeContainer"
                      :style="rtbzoneData.DeviceTypes.includes(item.value) ? 'background-color: rgb(237 237 243 / 38%);' : ''">
                      <label class="col-sm cpos cpointer">
                        <input type="checkbox" class="global-checkbox" :checked="enabledDevice(item.value)"
                          @click="enableDevice($event, item.value)" />
                        <span class="col-sm">{{ item.name }}</span>
                      </label>
                    </li>
                  </ul>
                </a-tab-pane>
              </a-tabs>
              <!-- <a-divider>Countries Optimization for last 7 days</a-divider> -->
              <!-- <b-table :bordered="true" :current-page="countriesCurrentPage" :fields="filteredCountriesFields"
                :filter="filter" :hover="true" :items="showCountryOptimization" :per-page="countriesPerPage"
                :small="true" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :striped="true" :tbody-tr-class="rowClass" show-empty stacked="md" @filtered="onFiltered">
                <template #cell(CC)="data">
                  <div class="items-center"><img :src="matchIcon(countries, data.item.CC)" /> {{
          $store.getters['info/countryName'](data.item.CC)
        }}</div>
                </template>
                <template #cell(Requests)="data">
                  {{ $formatNumber(data.item.Requests) }}
                </template>
                <template #cell(Bids)="data">
                  {{ $formatNumber(data.item.Bids) }}
                </template>
                <template #cell(Impressions)="data">
                  {{ $formatNumber(data.item.Impressions) }}
                </template>
              </b-table>
              <b-row>
                <b-col class="my-1" md="6">
                  <p>Showing {{ (countriesCurrentPage - 1) * countriesPerPage }} to {{
          (((countriesCurrentPage - 1) * countriesPerPage) + countriesPerPage > countriesTotalRows ?
            countriesTotalRows : ((countriesCurrentPage - 1) * countriesPerPage) +
            countriesPerPage)
        }} of {{ parseInt(countriesTotalRows).toLocaleString() }} entries</p>
                </b-col>
                <b-col class="my-1 d-flex flex-row-reverse" md="6">
                  <b-pagination v-model="countriesCurrentPage" :per-page="countriesPerPage"
                    :total-rows="countriesTotalRows" class="my-0">
                  </b-pagination>
                </b-col>
              </b-row> -->
              <!-- <a-divider>Sizes Optimization for last 7 days</a-divider>
              <b-table :bordered="true" :current-page="sizesCurrentPage" :fields="filteredSizesFields" :filter="filter"
                :hover="true" :items="showSizesOptimization" :per-page="sizesPerPage" :small="true"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :striped="true"
                :tbody-tr-class="rowClass" show-empty stacked="md" @filtered="onFiltered">
                <template #cell(CC)="data">
                  <div class="items-center"><img :src="matchIcon(countries, data.item.CC)" /> {{
          $store.getters['info/countryName'](data.item.CC)
        }}</div>
                </template>
                <template #cell(Requests)="data">
                  {{ $formatNumber(data.item.Requests) }}
                </template>
                <template #cell(Bids)="data">
                  {{ $formatNumber(data.item.Bids) }}
                </template>
                <template #cell(Impressions)="data">
                  {{ $formatNumber(data.item.Impressions) }}
                </template>
              </b-table> -->
              <!-- <b-row>
                <b-col class="my-1" md="6">
                  <p>Showing {{ (sizesCurrentPage - 1) * sizesPerPage }} to {{
          (((sizesCurrentPage - 1) * sizesPerPage) + sizesPerPage > sizesTotalRows ? sizesTotalRows :
            ((sizesCurrentPage - 1) * sizesPerPage) +
            sizesPerPage)
        }} of {{ parseInt(sizesTotalRows).toLocaleString() }} entries</p>
                </b-col>
                <b-col class="my-1 d-flex flex-row-reverse" md="6">
                  <b-pagination v-model="sizesCurrentPage" :per-page="sizesPerPage" :total-rows="sizesTotalRows"
                    class="my-0">
                  </b-pagination>
                </b-col>
              </b-row> -->
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button"
                    :class="'btn btn-' + (rtbzoneData.Status === 0 ? 'danger' : 'primary') + ' px-5 ml-2'"
                    @click.prevent="toggleRTBZoneStatus">
                    {{ rtbzoneData.Status == 0 ? 'Deactivate' : 'Activate' }} RTB Zone
                  </button>
                  <button type="button" @click="$router.push({ name: 'rtbzones' })"
                    class="btn btn-light px-5 ml-2">Cancel
                  </button>
                </div>
              </div>
            </a-form>
            <a-spin v-if="rtbzoneData === null" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { Modal } from 'ant-design-vue'
// import treeListRtb from '@/components/custom/TreeListRtb'
import { getIabCategories } from '@/api/info'
import staticCountryData from '@/resources/countries.json'
import { getRTBZoneById } from '@/api/display/rtbzone'
import zoneSize from '@/views/display/data/zoneSizeRemoteRTBZones.json'
import Vue from 'vue'

export default {
  // components: { treeListRtb },
  computed: {
    ...mapState(['info']),
    ...mapGetters('remotertbzone', ['getRemoteRTBZones', 'getActiveRemoteRTBZones']),
    ...mapGetters('ortbCampaign', ['getCampaigns', 'getActiveORTBCampaigns']),
    ...mapGetters('dsppopfeed', ['getDspPopFeeds', 'getActiveDspPopFeeds']),
    ...mapGetters('ORTBiplist', ['getIpLists', 'getActiveIpLists']),
    ...mapGetters('applist', ['getAppLists', 'getActiveAppLists']),
    ...mapGetters('domainlist', ['getDomainLists', 'getActiveDomainLists']),
    activeRemoteRTBZones: function () {
      return this.getActiveRemoteRTBZones
    },
    activeCampaigns: function () {
      return this.getActiveORTBCampaigns
    },
    activeDsppopfeeds: function () {
      return this.getActiveDspPopFeeds
    },
    checkedComputedKeys: {
      // getter
      get: function () {
        return this.treeData.length === 0 ? [] : this.checkedKeys
      },
      // setter
      set: function (newValue) {
        this.checkedKeys = newValue
      },
    },
    activeIpLists: function () {
      return this.getActiveIpLists.filter(el => el.Global === 0)
    },
    activeAppLists: function () {
      return this.getActiveAppLists.filter(el => el.Global === 0)
    },
    activeDomainLists: function () {
      return this.getActiveDomainLists.filter(el => el.Global === 0)
    },
    pubIdListString() {
      return this.pubIdList.join('\n')
    },
    filteredCountriesFields() {
      return this.countriesFields.filter(c => c.show)
    },
    filteredSizesFields() {
      return this.sizesFields.filter(c => c.show)
    },
    showCountryOptimization() {
      return this.rtbzoneData.OptimizationCC !== [] ? this.rtbzoneData.OptimizationCC : []
      // return this.showActive ? this.getActiveRTBZones : this.getRTBZones
    },
    showSizesOptimization() {
      return this.rtbzoneData.OptimizationSizes !== [] ? this.rtbzoneData.OptimizationSizes : []
    },
    enabledCountries: function () {
      if (this.displayEnabledCountries === true) {
        return this.countriesList.filter(el => el.Enabled === true)
      } else if (this.filterSearch !== '') {
        return this.countriesList.filter(el => {
          return el.Country.toLowerCase().indexOf(this.filterSearch.toLowerCase()) !== -1 ||
            el.CC.toLowerCase().indexOf(this.filterSearch.toLowerCase()) !== -1
        })
      }
      return this.countriesList
    },
    videoSize: function () {
      const index = this.videoSizes.findIndex((el) => {
        return el.size.width === this.rtbzoneData.Width && el.size.height === this.rtbzoneData.Height
      })
      return index === -1 ? this.videoSizes.length - 1 : index
    },
    countriesTotalRows() {
      return this.showCountryOptimization.length
    },
    sizesTotalRows() {
      return this.showSizesOptimization.length
    },
  },
  data() {
    return {
      sizes: zoneSize,
      selectedSizes: [],
      selectedKeys: [],
      checkedKeys: [],
      autoExpandParent: false,
      rtbzoneData: null,
      countries: staticCountryData,
      treeData: [],
      unformattedResponse: [],
      domainsBrandsListMode: undefined,
      pubIdList: [],
      cridList: [],
      cridListMode: undefined,
      OSData: null,
      displayEnabledCountries: false,
      BrowserData: null,
      filterSearch: '',
      countriesList: [],
      selectAllCountries: false,
      // countriesCurrentPage: 1,
      // sizesCurrentPage: 1,
      countriesPerPage: 50,
      sizesPerPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: 'Bids',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      domainRegExp: null,
      bannerEnabled: undefined,
      videoEnabled: undefined,
      audioEnabled: undefined,
      nativeEnabled: undefined,
      popEnabled: undefined,
      webEnabled: undefined,
      appEnabled: undefined,
      nonEmptyRefererEnabled: undefined,
      autoOpt: undefined,
      popAdmXml: undefined,
      refererMatchEnabled: undefined,
      ipMatchEnabled: undefined,
      uaMatchEnabled: undefined,
      deviceTypes: [
        {
          name: 'Desktop',
          value: 1,
        },
        {
          name: 'Mobile',
          value: 2,
        },
        {
          name: 'Tablet',
          value: 3,
        },
        {
          name: 'CTV',
          value: 4,
        },
        {
          name: 'Other',
          value: 5,
        },
      ],
      countriesFields: [
        {
          key: 'CC',
          label: 'Country',
          show: true,
          headerTitle: 'Country',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'Requests',
          label: 'Requests',
          show: true,
          formatter: '$formatNumber',
          headerTitle: 'Requests',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:100px',
          sortable: true,
        },
        {
          key: 'Bids',
          label: 'Bids',
          show: true,
          formatter: '$formatNumber',
          headerTitle: 'Bids',
          thClass: 'text-center',
          tdClass: 'text-right',
          sortDirection: 'desc',
          thStyle: 'width:100px',
          sortable: true,
        },
        {
          key: 'Impressions',
          label: 'Impressions',
          show: true,
          formatter: '$formatNumber',
          headerTitle: 'Impressions',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:110px',
          sortable: true,
        },
      ],
      sizesFields: [
        {
          key: 'Size',
          label: 'Size',
          show: true,
          headerTitle: 'Size',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'Requests',
          label: 'Requests',
          show: true,
          formatter: '$formatNumber',
          headerTitle: 'Requests',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:100px',
          sortable: true,
        },
        {
          key: 'Bids',
          label: 'Bids',
          show: true,
          formatter: '$formatNumber',
          headerTitle: 'Bids',
          thClass: 'text-center',
          tdClass: 'text-right',
          sortDirection: 'desc',
          thStyle: 'width:100px',
          sortable: true,
        },
        {
          key: 'Impressions',
          label: 'Impressions',
          show: true,
          formatter: '$formatNumber',
          headerTitle: 'Impressions',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:110px',
          sortable: true,
        },
      ],
      keywordListsType: [
        {
          Id: 0,
          Name: 'Exact',
        },
        {
          Id: 1,
          Name: 'Phrase',
        },
        {
          Id: 2,
          Name: 'Broad',
        },
      ],
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      supplyLabelCol: {
        xs: { span: 18 },
        sm: { span: 5 },
      },
      supplyWrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    enabledDevice(value) {
      if (this.rtbzoneData.DeviceTypes.includes(value)) {
        return true
      } else {
        return false
      }
    },
    cpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Value can not be negative.')
      }
      message()
    },
    maxBidValidator(rule, value, message) {
      if (value === '') {
        message('Max. Bidfloor can not be empty')
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Max. Bidfloor can not be negative.')
      } else if (parseFloat(value) > 100) {
        message('Max. Bidfloor can not be greater than 100.')
      }
      message()
    },
    minBidValidator(rule, value, message) {
      if (value === '') {
        message('Min. Bidfloor can not be empty')
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Min. Bidfloor can not be negative.')
      } else if (parseFloat(value) > 100) {
        message('Min. Bidfloor can not be greater than 100.')
      } else if (parseFloat(this.form.getFieldValue('maxBidfloor')) < parseFloat(value)) {
        message('Min. Bidfloor can not be greater than Max. Bidfloor.')
      }
      message()
    },
    toggleRTBZoneStatus() {
      const status = this.rtbzoneData.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change RTB Zone status?',
        content: h => <div>{status} RTB Zone <strong>{this.rtbzoneData.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = this.rtbzoneData.Status === 0 ? 1 : 0
          this.$store.dispatch('rtbzone/CHANGE_STATUS', {
            id: this.rtbzoneData.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    copyList(type) {
      if (type === 'apps') {
        navigator.clipboard.writeText(this.rtbzoneData.AdAppsBlacklist)
        Vue.prototype.$notification.success({
          message: 'Ad Apps Blacklist copied to clipboard.',
        })
      } else if (type === 'brands') {
        navigator.clipboard.writeText(this.rtbzoneData.AdDomainsBrandsBlacklist)
        Vue.prototype.$notification.success({
          message: 'Ad Domains/Brands Blacklist copied to clipboard.',
        })
      } else if (type === 'pubIds') {
        navigator.clipboard.writeText(this.rtbzoneData.PubIdList)
        Vue.prototype.$notification.success({
          message: 'Pub ID List copied to clipboard.',
        })
      } else if (type === 'crids') {
        navigator.clipboard.writeText(this.rtbzoneData.CridList)
        Vue.prototype.$notification.success({
          message: 'CRID List copied to clipboard.',
        })
      }
    },
    handlePubIdList(data) {
      this.rtbzoneData.PubIdList = data.join('\n')
    },
    handleCridList(data) {
      this.rtbzoneData.CridList = data.join('\n')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.countriesTotalRows = filteredItems.length
      // this.currentPage = 1
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) {
        return false
      } else {
        const image = cc.image
        return image
      }
    },
    enableCountry(event, row) {
      const itemIndex = this.rtbzoneData.Countries.findIndex(el => el.CC === row.CC)
      if (itemIndex === -1) {
        this.rtbzoneData.Countries.push({
          Country: row.Country,
          CC: row.CC,
          Enabled: true,
        })
      } else {
        this.rtbzoneData.Countries[itemIndex].Enabled = event.target.checked
      }
    },
    enableDevice(event, value) {
      const index = this.rtbzoneData.DeviceTypes.indexOf(value)
      if (index === -1) {
        this.rtbzoneData.DeviceTypes.push(value)
      } else {
        this.rtbzoneData.DeviceTypes.splice(index, 1)
      }
    },
    populateCountry: function () {
      if (this.countriesList.length > 0 && this.rtbzoneData !== null && this.rtbzoneData.Countries.length > 0) {
        for (const index in this.countriesList) {
          const country = this.rtbzoneData.Countries.filter(ob => ob.CC === this.countriesList[index].CC)
          if (typeof country[0] !== 'undefined') {
            this.countriesList[index].Enabled = true
          }
        }
      }
    },
    handleCountries() {
      this.countriesList.forEach(el => {
        el.Enabled = !this.selectAllCountries
      })
    },
    $_formatTreeData(type = 'os') {
      const searchData = type === 'os' ? this.OSData : this.BrowserData
      const formatedData = []
      searchData.forEach(el => {
        // Add if all versions selected
        if (el.enabled) {
          const temp = {
            Family: el.Family,
            BidAdjustment: 100,
          }
          if (el.Versions.length > 0) {
            const tmpV = []
            el.Versions.forEach(ss => {
              if (ss.enabled) {
                const temp2 = {
                  BidAdjustment: 100,
                  Major: ss.Major,
                }
                if (typeof ss.Minor !== 'undefined') temp2.Minor = ss.Minor
                tmpV.push(temp2)
              }
            })
            temp.Versions = tmpV
          }
          if (typeof el.AutoIncludeNewBrowsers !== 'undefined') temp.AutoIncludeNewBrowsers = el.AutoIncludeNewBrowsers ? 1 : 0
          formatedData.push(temp)
          return
        }
        // Filter versions
        const versions = []
        el.Versions.length > 0 && el.Versions.forEach(ver => {
          if (ver.enabled) {
            const temp = {
              BidAdjustment: 100,
              Major: ver.Major,
            }
            if (typeof ver.Minor !== 'undefined') temp.Minor = ver.Minor
            versions.push(temp)
          }
        })
        // Add if one or more versions is used
        if (versions.length > 0) {
          const tmp = {
            Family: el.Family,
            BidAdjustment: 100,
            Versions: versions,
          }
          if (typeof el.AutoIncludeNewBrowsers !== 'undefined') tmp.AutoIncludeNewBrowsers = el.AutoIncludeNewBrowsers ? 1 : 0
          formatedData.push(tmp)
        }
      })
      return formatedData
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    handleAdDomains(data) {
      this.rtbzoneData.AdDomainsBrandsBlacklist = data.join('\n')
    },
    handleAppsBlacklist(data) {
      this.rtbzoneData.AdAppsBlacklist = data.join('\n')
    },
    handleSeatList(data) {
      this.rtbzoneData.SeatList = data.join('\n')
    },
    goBack() {
      this.$router.go(-1)
    },
    handleSubmit() {
      const countries = []
      this.countriesList.forEach(el => {
        if (el.Enabled) {
          countries.push({
            CC: el.CC,
            BidAdjustment: 100,
          })
        }
      })
      // const oss = this.$_formatTreeData('os')
      // const browsers = this.$_formatTreeData('browser')

      this.form.validateFields((err, values) => {
        if (!err) {
          values.PublisherId = this.rtbzoneData.PublisherId
          values.DomainsBrandsListMode = this.rtbzoneData.DomainsBrandsListMode
          values.PubIdList = this.rtbzoneData.PubIdList
          values.CridList = this.rtbzoneData.CridList
          values.DeviceTypes = this.rtbzoneData.DeviceTypes
          values.AdDomainsBrandsBlacklist = this.rtbzoneData.AdDomainsBrandsBlacklist
          values.AdAppsBlacklist = this.rtbzoneData.AdAppsBlacklist
          values.BannerEnabled = this.bannerEnabled ? 1 : 0
          values.VideoEnabled = this.videoEnabled ? 1 : 0
          values.AudioEnabled = this.audioEnabled ? 1 : 0
          values.NativeEnabled = this.nativeEnabled ? 1 : 0
          values.PopEnabled = this.popEnabled ? 1 : 0
          values.WebEnabled = this.webEnabled ? 1 : 0
          values.AppEnabled = this.appEnabled ? 1 : 0
          values.AutoOptimize = this.autoOpt ? 1 : 0
          values.PopAdmXml = this.popAdmXml ? 1 : 0
          values.NonEmptyReferer = this.nonEmptyRefererEnabled ? 1 : 0
          values.RefererMatch = this.refererMatchEnabled ? 1 : 0
          values.IpMatch = this.ipMatchEnabled ? 1 : 0
          values.UaMatch = this.uaMatchEnabled ? 1 : 0
          const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          const newArr = this.checkedKeys.filter(el => !array.includes(el))
          values.IabCategories = newArr
          this.$store.dispatch('rtbzone/UPDATE_RTBZONE', {
            id: this.rtbzoneData.Id,
            payload: {
              ...values,
              DefaultCPM: 1,
              Countries: countries,
              // Os: oss,
              // Browsers: browsers,
            },
            callback: function () {
              this.$router.push({ name: 'rtbzones' })
            }.bind(this),
          })
        }
      })
    },
    placeholder(key) {
      return (typeof this.rtbzoneData[key] === 'undefined') ? '' : this.rtbzoneData[key]
    },

  },
  created() {
    getIabCategories().then(response => {
      const formattedResponse = []
      response.forEach((el, id) => {
        const temp = {
          title: '(' + el.Name + ')' + ' ' + el.Title,
          key: el.Name,
          children: [],
        }
        el.Subcategories.forEach((element) => {
          temp.children.push({
            title: '(' + element.Name + ')' + ' ' + element.Title,
            key: element.Name,
          })
        })
        formattedResponse.push(temp)
        this.treeData = formattedResponse
      })
      response.forEach(element => {
        this.unformattedResponse.push(element.Name)
      })
    })
    this.$store.dispatch('info/LOAD_COUNTRIES').then((res) => {
      res.forEach((el) => {
        this.countriesList.push({
          Country: el.Country,
          CC: el.CC,
          Enabled: false,
        })
      })
      this.populateCountry()
    })
    this.$store.dispatch('remotertbzone/LOAD_ZONES_SHORT')
    this.$store.dispatch('dsppopfeed/LOAD_FEEDS_SHORT')
    this.$store.dispatch('ORTBiplist/LOAD_IPLISTS')
    this.$store.dispatch('applist/LOAD_APPLISTS')
    this.$store.dispatch('domainlist/LOAD_DOMAINLISTS')
    this.$store.dispatch('ortbCampaign/LOAD_CAMPAIGNS_SHORT')
    getRTBZoneById(this.$route.params.id).then((response) => {
      this.rtbzoneData = response
      this.checkedKeys = this.rtbzoneData.IabCategories
      document.title = `${this.rtbzoneData.Name} - RTB.ME | Edit RTB Zone` || document.title
      this.populateCountry()
      this.sizes.forEach(el => {
        if (response.SizesList.includes(`${el.size.width}x${el.size.height}`)) {
          this.selectedSizes.push(`${el.size.width}x${el.size.height}`)
        }
      })
    })
    this.domainRegExp = new RegExp(/^(?!https?:\/\/|www\.).*$/)
  },
  watch: {
    rtbzoneData(val) {
      if (val !== undefined) {
        this.bannerEnabled = val.BannerEnabled === 1
        this.videoEnabled = val.VideoEnabled === 1
        this.audioEnabled = val.AudioEnabled === 1
        this.nativeEnabled = val.NativeEnabled === 1
        this.popEnabled = val.PopEnabled === 1
        this.webEnabled = val.WebEnabled === 1
        this.appEnabled = val.AppEnabled === 1
        this.nonEmptyRefererEnabled = val.NonEmptyReferer === 1
        this.autoOpt = val.AutoOptimize === 1
        this.popAdmXml = val.PopAdmXml === 1
        this.refererMatchEnabled = val.RefererMatch === 1
        this.ipMatchEnabled = val.IpMatch === 1
        this.uaMatchEnabled = val.UaMatch === 1
      }
    },
  },
}

</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}

.list-flex {
  display: flex;
  align-content: flex-start;
}

.select-width {
  width: 90%;
}

.copy-button-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem !important;
  height: 2.1rem !important;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.custom-table th,
.custom-table td {
  padding: 0 0 0 6px !important;
  height: 30;
  text-align: center;
}

.no-records-to-show {
  text-align: center;
  text-decoration: none;
  font-weight: normal;
}

.ant-table-small {
  border: none !important;
}

/** */
.arrow.left::before {
  content: "▶";
  height: 10px;
  margin-right: 5px;
}

.arrow.down::before {
  content: "▼";
  height: 10px;
  margin-right: 5px;
}

.bid-adjustment {
  display: inline;
  width: 80px !important;
  padding: 0 5px;
  font-size: 0.9rem;
  height: 1.7rem;
}

.checkbox {
  position: relative;
  top: 2px;
}

.list-group-item {
  padding: .1rem 1.25rem !important;
}

.treeContainer {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.treeContainerV {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  /* flex-wrap: wrap; */
}

label {
  opacity: 0.8;
}

.no-border {
  border: none;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 1px !important;
}

.global-checkbox {
  position: relative;
  top: 2px;
}

.cpos-half {
  position: relative;
  top: 2px;
}

.cpos {
  position: relative;
  top: 4px;
}

.version-checkbox {
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.no-margin {
  margin-bottom: 0;
}

.cpointer {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .-m {
    margin-right: -4rem;
  }

  .break-line>>>li .ant-tree-node-content-wrapper {
    margin-bottom: 5px;
    padding-top: 4px;
    display: inline-block !important;
    white-space: pre-wrap !important;
    line-height: 15px !important;
    width: 90% !important;
  }
}
</style>
